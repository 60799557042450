// model
import { userTaskLabel } from "@/model/workflow/task/taskModel";
import { actionResultType } from "@/model/action/actionModel";

// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { baseComponentMixin } from "@/mixins/shared/base/component/baseComponentMixin";

export const userTaskAssignmentMixin = {
  mixins: [dialogOutlineMixin, baseComponentMixin],
  components: {
    TaskDialogHeader: () => import("@/components/task/TaskDialogHeader")
  },
  props: {
    /**
     * @type {{taskId: number, typeId: number, actorId: number, actorName: string, userName: string, assignee: string, canReassign: boolean, canceledRecipients: string, comment: string, dueDate: string, duration: string, isDue: boolean, isMilestone: boolean, isOverdue: boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, recordName: string, status: number, statusText: string, notes: {id: number, created: string, taskId: number, userId: number, userName: string}[]}}
     */
    task: undefined,

    /**
     * Function to perform
     */
    performAction: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      /**
       * isValidForm
       * @type boolean
       */
      isValidForm: false,

      /**
       * send Notification after command's execution
       * @type boolean
       */
      sendNotification: false,

      /**
       * comment
       * @type {string}
       */
      comment: ""
    };
  },
  computed: {
    /**
     * command
     * @type {{type: number, label: string, icon: string, description: string} | undefined}
     */
    command() {
      console.warn(`virtual computed called: ${this.$options.name}.command() `);
      return undefined;
    },
    /**
     * current operation
     * @return {{name: string, icon: string, label: string}} release User Task Operation
     */
    operation() {
      return {
        name: this.command?.label,
        icon: this.command?.icon,
        label: this.command?.label
      };
    },

    /**
     * operation Label
     * @return {string}
     */
    operationLabel() {
      return this.operation?.label;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.operation?.icon;
    },

    /**
     * Dialog title
     * @return {string} Assign Manager Dialog title
     */
    dialogTitle() {
      return `${this.operationLabel} ${userTaskLabel}`;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return this.operationLabel;
    },

    /**
     * Ok Command Tooltip
     * @return {string}
     */
    dialogOkCommandTooltip() {
      return this.command?.description;
    },

    /**
     * Cancel Command Tooltip
     * @return {string}
     */
    dialogCancelCommandTooltip() {
      return `Cancel ${this.dialogTitle} and close Dialog`;
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operationLabel}'`;
    },

    /**
     * User Notification Label
     * @return {string}
     */
    notificationLabel() {
      return "Send Notification";
    },

    /**
     * Comment Label
     * @return {string}
     */
    commentLabel() {
      return `${this.operationLabel} comment`;
    }
  },
  methods: {
    async onOk() {
      try {
        this.clearAlert();
        this.displayProgress = true;

        const result = await this.doPerformAction();

        if (result?.type === actionResultType.success) {
          await this.autoCloseDialog(result?.message);
        } else {
          this.internalAlert = this.createAlert(
            this.alertTypeName.error,
            this.formatAlertError(this.action, result?.message),
            false
          );
        }
      } catch (e) {
        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e),
          false
        );
      } finally {
        this.displayProgress = false;
      }
    },
    /**
     * virtual doPerformAction
     * @return  {{type: string, message: string, outcome: string}|undefined}
     */
    async doPerformAction() {
      console.warn(
        `virtual function called: ${this.$options.name}.doPerformAction() `
      );
      return undefined;
    }
  },
  watch: {
    /**
     * Watcher on Task Changed
     */
    async task() {
      this.sendNotification = false;
      this.comment = "";
    }
  }
};
